import classNames from "classnames"
import React, { useState, useCallback } from "react"

import { Color } from "../../../../constants/V2/color"
import CircledIconButton from "../../../elements/V2/Buttons/CircledIconButton"
import GatsbyStoryblokImage from "../../../elements/V2/GatsbyStoryblokImage"
import Typography from "../../../elements/V2/Typography"
import Video from "../../../elements/V2/Video"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type BaseProps = {
  backgroundVideo?: string
  backgroundImage: string
  backgroundImageAlt: string
  type: "imageWithIcon" | "bottomImageOverlay" | "callToAction"
}

type imageProps = BaseProps &
  Partial<{
    // type: "imageWithIcon"
    icon?: string
    iconAlt?: string
  }>

type bottomImageOverlayProps = BaseProps &
  Partial<{
    // type: "bottomImageOverlay"
    overlayImage: string
    overlayImageAlt: string
  }>

type callToActionProps = BaseProps &
  Partial<{
    // type: "callToAction"
    accentColor: Color
    superscriptText?: string
    titleText: string
    descriptionText: string
    buttonLink: string
  }>

const RichMediaMaster: React.FC<
  imageProps & bottomImageOverlayProps & callToActionProps
> = ({
  backgroundVideo,
  backgroundImage,
  backgroundImageAlt,
  type,
  icon,
  iconAlt,
  overlayImage,
  overlayImageAlt,
  accentColor,
  superscriptText,
  titleText,
  descriptionText,
  buttonLink,
  ...props
}) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)
  const [videoContainerHover, setVideoContainerHover] = useState(false)

  const onMouseEnter = useCallback(() => {
    setVideoContainerHover(true)
  }, [])

  const onMouseLeave = useCallback(() => {
    setVideoContainerHover(false)
  }, [])

  return (
    <div
      ref={animationRefCallback}
      onMouseEnter={backgroundVideo ? onMouseEnter : undefined}
      onMouseLeave={backgroundVideo ? onMouseLeave : undefined}
      className={classNames(
        "relative grid grid-cols-1 col-span-full sm-v2:col-span-2 md-v2:col-span-3 lg-v2:col-span-6"
      )}
      {...props}
    >
      {backgroundVideo ? (
        <Video
          video={backgroundVideo}
          coverImage={backgroundImage}
          ImageAlt={backgroundImageAlt}
          aspectRatio="1:1"
          playbackMode="hover"
          videoContainerHover={videoContainerHover}
          className="rounded-lg-v2 w-auto h-full"
          rounded={true}
        />
      ) : (
        <GatsbyStoryblokImage
          aspectRatio="1:1"
          image={backgroundImage}
          alt={backgroundImageAlt}
          className="rounded-lg-v2"
        />
      )}
      {type === "imageWithIcon" && icon && iconAlt && (
        <GatsbyStoryblokImage
          image={icon}
          alt={iconAlt}
          className="w-1/3 absolute top-20-v2 left-20-v2 md-v2:top-24-v2 md-v2:left-24-v2 lg-v2:top-32-v2 lg-v2:left-32-v2"
        />
      )}
      {type === "bottomImageOverlay" && overlayImage && overlayImageAlt && (
        <div className="absolute -bottom-32-v2 md-v2:-bottom-48-v2 lg-v2:-bottom-[60px] left-0">
          <GatsbyStoryblokImage
            aspectRatio="16:9"
            image={overlayImage}
            alt={overlayImageAlt}
            width={375}
          />
        </div>
      )}
      {type === "callToAction" && accentColor && titleText && descriptionText && (
        <div
          className={classNames(
            "absolute top-0 left-0 w-full h-full p-lg-v2 flex flex-col",
            superscriptText ? "justify-between" : "justify-end"
          )}
        >
          {superscriptText && (
            <Typography
              font="grotesk"
              weight="medium"
              size="subscript-lg"
              color={accentColor}
              text={superscriptText}
              className="uppercase"
            />
          )}

          <div
            className={classNames(
              getBackgroundColorClass(accentColor),
              "bg-opacity-50 backdrop-blur rounded-16-v2 lg-v2:rounded-20-v2 p-20-v2 lg-v2:p-24-v2 flex justify-between items-start gap-24-v2 lg-v2:gap-32-v2 transform-gpu"
            )}
          >
            <div>
              <Typography
                font="grotesk"
                weight="medium"
                size="lead-lg"
                color={getAccentColor(accentColor)}
                text={titleText}
                className="mb-8-v2"
              />
              <Typography
                font="grotesk"
                weight="book"
                size="body-lg"
                color={getAccentColor(accentColor)}
                text={descriptionText}
              />
            </div>
            <CircledIconButton
              color={getAccentColor(accentColor)}
              linkUrl={buttonLink}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default RichMediaMaster
