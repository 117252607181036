import React from "react"

import RichMediaMaster from "./RichMediaMaster"
import { Color } from "../../../../constants/V2/color"

type Props = {
  backgroundVideo?: string
  backgroundImage: string
  backgroundImageAlt: string
  accentColor: Color
  superscriptText?: string
  titleText: string
  descriptionText: string
  buttonLink: string
}

const InfoTab: React.FC<Props> = (props) => {
  return <RichMediaMaster type="callToAction" {...props} />
}

export default InfoTab
