import { storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import RichMediaInfoTabCard from "../../../../V2/Cards/RichMedia/InfoTab"

import { getUrlFromStoryblokLink } from "@utils/storyblok"

export default function StoryblokRichMediaInfoTabCard({
  blok,
}: Storyblok.BlokProps<Storyblok.RichMediaInfoTabCard>) {
  return (
    <RichMediaInfoTabCard
      backgroundVideo={
        blok.backgroundVideoUrl?.url
          ? getUrlFromStoryblokLink(blok.backgroundVideoUrl)
          : ""
      }
      backgroundImage={blok.backgroundImage.filename}
      backgroundImageAlt={blok.backgroundImage.alt}
      accentColor={blok.accentColor}
      superscriptText={blok.superscriptText}
      titleText={blok.titleText}
      descriptionText={blok.descriptionText}
      buttonLink={getUrlFromStoryblokLink(blok.ctaLink)}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    />
  )
}
